/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

//global variable

function hideMenuLevelHigher(currentMenuLevel) {
    $(".conditional-menu").each(function () {
        if ($(this).attr("data-menu-level") > currentMenuLevel) {
            $(this).hide()
        }
    })
}

//function setSession(name, value){
//    var param = "name="+name+"&value="+value
//
//    $.ajax({
//       type        :  "POST",
//       url         :  "set_session",
//       data        :  param,
//       cache       :  false,
//       async       :  true,
//       success     :  function(data) {
//                        console.log(data)
//                      },
//       statusCode  :  {
//                         404: function() {
//                            alert("page not found")
//                         }
//                      }
//    })
//}
//
//function clearHigherMenuLevelSession(currentMenuLevel) {
//    $(".conditional-menu").each(function () {
//        if ($(this).attr("data-menu-level") > currentMenuLevel) {
//            setSession("level"+$(this).attr("data-menu-level"),"")
//        }
//    })
//}

// records user selections
function setUserSelection(elem){
    try {
        var arrFor = $(elem).attr("data-set-target").split(" ");
        var arrVal = $(elem).attr("data-set-value").split(" ");
        
        for (var i = 0; i < arrFor.length; i++) {
            var name = arrFor[i];
            var value = arrVal[i];

            $("[name="+name+"]").val(value);

            // clean up => this is no longer required as each element now setting all inpu
            // $("input.user-selection").each(function(){
            //     if ( $(this).attr("data-order") > $("[name="+name+"]").attr("data-order") ) {
            //         $(this).val("");
            //     }
            // });
        }
    }
    catch(err){

    }
}

function toggleMenu(elem){
    try {
        var target = $(elem).attr("data-target");
        $("#" + target).show();
        
        //scroll to the just been shown element
        $("html, body").animate({ scrollTop: $("#"+target).offset().top }, 500);            
    }
    catch(err){

    }    
}

$(function () {

    //top level option => decides conditional-menu-level1
    $("a.menu-option").click(function (e) {
        // e.preventDefault()

        //hide all menu-level higher than the one the clicked element sitting on
        var currentMenuLevel = $(this).parents(".conditional-menu").attr("data-menu-level");
        hideMenuLevelHigher(currentMenuLevel);
            
        //interface action
        toggleMenu($(this));
        
    });

    // stores user selection
    $("a.btn").click(function(e){
        setUserSelection($(this));
    
        // if it is a submit button
        if ( $(this).hasClass("submit-button") ) {
            $("#decision-tree-form").submit();
        }
    });

});
